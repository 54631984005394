const Index = () => import('./components/index.vue');
const CreateMediaStory = () => import('./components/create-media-story.vue');
const CreateMediaFeed = () => import('./components/create-media-feed.vue');

export default [
  {
    path: '/medias',
    name: 'medias.index',
    meta: {
      title: 'Mídias',
    },
    component: Index,
    children: [
      {
        path: '/medias/stories',
        name: 'medias.stories.index',
        meta: {
          title: 'Criar mídia de stories',
        },
        component: CreateMediaStory,
      },
      {
        path: '/medias/feed',
        name: 'medias.feed.index',
        meta: {
          title: 'Criar mídia de feed',
        },
        component: CreateMediaFeed,
      },
    ],
  },
];
