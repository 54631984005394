<script>
import { mapGetters } from 'vuex';
export default {
  name: 'ASide',

  data() {
    return {
      showDelivery: false,
    };
  },

  computed: { ...mapGetters(['city']) },

  mounted() {
    setTimeout(() => {
      this.showDelivery = true;
    }, 2000);
  },
};
</script>

<template>
  <aside class="main-sidebar sidebar-dark-orange elevation-4" v-if="city">
    <!-- Brand Logo -->
    <router-link class="brand-link" :to="{ name: 'dashboard' }">
      <img
        src="https://cdn.boradelivery.com.br/images/logo.png"
        alt="Bora Delivery"
        class="brand-image"
      />
      <span class="brand-text font-weight-light">Bora Delivery</span>
    </router-link>
    <div class="sidebar">
      <nav class="mt-2">
        <ul
          class="nav nav-pills nav-sidebar flex-column nav-child-indent nav-flat"
          data-widget="treeview"
          role="menu"
          data-accordion="false"
        >
          <li class="nav-item">
            <router-link :to="{ name: 'dashboard' }" class="nav-link">
              <i class="nav-icon fas fa-tachometer-alt"></i>
              <p>Dashboard</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'companies.index' }" class="nav-link">
              <i class="nav-icon fas fa-building"></i>
              <p>Empresas</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'orders.index' }" class="nav-link">
              <i class="nav-icon fas fa-receipt"></i>
              <p>Pedidos</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'ads.index' }" class="nav-link">
              <i class="nav-icon fas fa-bullhorn"></i>
              <p>Publicidade no App</p>
            </router-link>
          </li>
          <li class="nav-item" v-if="showDelivery">
            <a href="#" class="nav-link">
              <i class="nav-icon fas fa-truck"></i>
              <p>Delivery <i class="fas fa-angle-left right"></i></p>
            </a>
            <ul class="nav nav-treeview nav-child-indent">
              <li class="nav-item" v-if="city.allow_deliverymen">
                <router-link :to="{ name: 'deliveryman.index' }" class="nav-link">
                  <i class="nav-icon fas fa-user-ninja"></i>
                  <p>Entregadores</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'neighborhoods.index' }" class="nav-link">
                  <i class="nav-icon fas fa-layer-group"></i>
                  <p>Bairros</p>
                </router-link>
              </li>
              <li class="nav-item" v-if="city.allow_deliverymen">
                <router-link :to="{ name: 'deliveryman.map' }" class="nav-link">
                  <i class="nav-icon fas fa-map"></i>
                  <p>Mapa</p>
                </router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'plans.index' }" class="nav-link">
              <i class="nav-icon fas fa-columns"></i>
              <p>Planos</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'invoices.index' }" class="nav-link">
              <i class="nav-icon fas fa-receipt"></i>
              <p>Faturas</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'reports.index' }" class="nav-link">
              <i class="nav-icon fas fa-align-justify"></i>
              <p>Relatórios</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'coupons.index' }" class="nav-link">
              <i class="nav-icon fas fa-ticket-alt"></i>
              <p>Cupons</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'notifications' }" class="nav-link">
              <i class="nav-icon fas fa-bell"></i>
              <p>Notificações</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'users.index' }" class="nav-link">
              <i class="nav-icon fas fa-users"></i>
              <p>Usuários</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'medias.index' }" class="nav-link">
              <i class="nav-icon fas fa-photo-video"></i>
              <p>Mídias</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'sub-accounts.index' }" class="nav-link">
              <i class="nav-icon fas fa-university"></i>
              <p>Dados bancários</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'settings.index' }" class="nav-link">
              <i class="nav-icon fas fa-cog"></i>
              <p>Configurações</p>
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
  </aside>
</template>
